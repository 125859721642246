body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background: rgb(138, 43, 226);
  background: linear-gradient(
    180deg,
    rgba(138, 43, 226, 1) 0%,
    rgba(159, 93, 221, 1) 77%,
    rgba(181, 144, 217, 1) 100%
  );
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

#root > span {
  align-self: center;
  font-size: 0.6rem;
}

#root > span > a {
  text-decoration: none;
  color: white;
}

h1 {
  text-transform: uppercase;
  align-self: center;
  padding-top: 5%;
}

.soapp_selector {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  margin-top: 10%;
}

.soapplistselector {
  display: flex;
  flex-flow: column nowrap;
}

.soapp_valor,
.soapp_label {
  align-self: center;
  font-size: 1.3rem;
}

.soapp_valor > h3 {
  margin: 0;
  padding-top: 50%;
}

.soapp_valor > h1 {
  color: #950404;
  font-weight: 900;
}

.soapp-loader{
  align-self: center;
}

select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;

  z-index: 1;

  outline: none;
}

select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  min-width: 15ch;
  max-width: 100%;
  border: 1px solid var(--select-border);
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
}
select::after {
  grid-area: select;
}
:not(.select--multiple)::after {
  content: "";
  justify-self: end;
  width: 0.8em;
  height: 0.5em;
  background-color: var(--select-arrow);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}

select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--select-focus);
  border-radius: inherit;
}



@media (max-width: 471px) {
  h1 {
    font-size: 1.3rem;
  }
  .soapp_valor > h1 {
    font-size: 2rem;
  }
}
